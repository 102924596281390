<template lang='pug'>
	.d-flex.flex-column.mx-auto.find
		h3.text-bold.mb-4(:class="{'b1': isMobile}") {{ $t('h1.find')}}

		.find__note.mb-4
			p.b3.mb-0 {{ $t('pages.needTelegram2') }}
			p.b3.mb-0 {{ $t('pages.canDownloadTelegram') }}&nbsp;
				a.text-primary(href='//telegram.org' target='_blank') www.telegram.org

		p.b3.text-grey.mb-4 {{ $t('bot.findPageDesc') }}


		p.b1.mb-3 {{ $t('bot.howToFind') }}:
		.d-flex.flex-column.b1.mb-3.position-relative
			.find__item {{ $t('bot.goTo') }}
			.find__item(v-html="$t('bot.pressStart')")
			.find__item.mb-0(v-html="$t('bot.pressMenu')")
			svg.find__dashed(xmlns="http://www.w3.org/2000/svg" width="2" height="86" viewBox="0 0 2 86" fill="none")
				path(opacity="0.3" d="M1 0.53125L1 89.5313" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 4")

		p.b3.text-grey.mb-3 {{ $t('bot.willFind') }}

		a.mb-3.find__button(:href='botPay' target='_blank')
			.btn.btn-primary {{ $t('project.openBot') }}

		p.b3.text-grey.mb-3 {{ $t('pages.ifCantDoTextToSupport') }}

		.d-flex.flex-column.flex-md-row.w-100
			a.d-block.mb-2.mb-md-0.mr-md-4.w-100.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
				pw-btn(:text="$t('h1.telegram')" :icon="'icon-telegram'")
			pw-btn-mail.w-100

		modal-cookie
</template>

<script>
import { bus } from '@/main';
import ModalCookie from '@/components/Modals/Cookie';
import PwBtn from '@/components/Buttons/PwBtn';
import PwBtnMail from '@/components/Buttons/PwBtnMail';

export default {
	name: 'Find',
	metaInfo () { return {title: bus.$t('h1.findSubscription')}},
	components: {
		ModalCookie,
		PwBtn,
		PwBtnMail
	},
	data: () => ({
		isDarkTheme: null
	}),
	beforeMount() {
		this.isDarkTheme = localStorage.getItem('themeColorPaymentPage').indexOf('dark') ? true : false;
		import("@/assets/css/sass/themes/piaf." + (!this.isDarkTheme ? "dark" : "light") + ".figma.scss")
	},
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.find {
	width: 100%;
	max-width: 620px;
	padding-bottom: 30px;

	@include respond-below(sm) {
		padding: 13px 0 30px;
	}

	&__note {
		padding: 20px;
		background-color: var(--brand-bg-primary);
		border-radius: var(--border-radius-rounded);
		margin-bottom: 20px;

		@include respond-below(sm) {
			padding: 15px 17px;
		}
	}

	&__item {
		position: relative;
		padding-left: 29px;
		margin-bottom: 18px;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background-color: var(--brand-primary);
			transform: translateY(-50%);
		}
	}

	&__dashed {
		position: absolute;
		top: 6px;
		left: 6px;
	}

	&__button {
		max-width: 290px;

		@include respond-below(sm) {
			max-width: 100%;
		}
	}
}
</style>
